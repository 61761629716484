import { css } from '@emotion/react';
import { FC } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import { colors } from '../../theme/colors';
import { PaildButton } from '../atoms/PaildButton';

export const TopFunctionSection: FC = () => {
  return (
    <section css={FunctionSectionStyle}>
      <div css={FunctionSectionBackgroundStyle}>
        <div css={{ position: 'relative', width: '100%', height: '100%' }}>
          <StaticImage
            src='../../images/top-view/background/background2.svg'
            alt='background2'
            placeholder='none'
            objectFit='contain'
            css={{
              position: 'absolute',
              top: 0,
              transform: 'translate(-50%, 0)',
              left: '50%',
              minWidth: 3292,

              '@media(max-width: 1119px)': {
                display: 'none',
              },
            }}
          />
          <StaticImage
            src='../../images/top-view/background/background4.svg'
            alt='background4'
            placeholder='none'
            objectFit='contain'
            objectPosition='left'
            css={{
              position: 'absolute',
              top: '100%',
              left: 0,
              width: '100%',
              display: 'none',

              '@media(max-width: 1119px)': {
                display: 'block',
              },
            }}
          />
        </div>
      </div>
      <div css={FunctionSectionInnerStyle}>
        <div css={FunctionSectionHeadingStyle}>
          <p>Feature</p>
          <h2>paildの機能</h2>
        </div>
        <div css={FunctionWrapperStyle}>
          <div css={FunctionWrapperInnerStyle}>
            <div css={FunctionImageWrapperStyle}>
              <StaticImage
                src='../../images/function/issue/card-amount.png'
                alt='オンライン上で即発行が可能'
                quality={100}
                placeholder='none'
                objectFit='contain'
                css={{
                  maxWidth: 580,
                  boxShadow: '0px 1.8px 8.1px 0px #00000040',
                }}
              />
            </div>
            <div css={FunctionDescriptionWrapperStyle}>
              <div css={FunctionHeadingStyle}>
                <h3>
                  オンライン上で
                  <br />
                  <strong>即発行が可能</strong>
                </h3>
              </div>
              <p>
                申請書類は一切不要。すべてオンライン上でいつでもカード発行が可能。カード名も自由に設定できるため、従業員、用途、拠点ごとのカード発行に最適。
              </p>
            </div>
          </div>
          <div css={FunctionWrapperInnerStyle}>
            <div css={FunctionImageWrapperStyle}>
              <StaticImage
                src='../../images/top-view/function-02.png'
                alt='カードごとに利用可能な金額や期間を制限'
                quality={100}
                placeholder='none'
                objectFit='contain'
                css={{ maxWidth: 580 }}
              />
            </div>
            <div css={FunctionDescriptionWrapperStyle}>
              <div css={FunctionHeadingStyle}>
                <h3>
                  カードごとに
                  <br
                    css={{
                      '@media(min-width: 769px)': {
                        display: 'none',
                      },
                    }}
                  />
                  <strong>利用可能な金額や期間</strong>
                  を制限
                </h3>
              </div>
              <p>
                予算や業務に合わせて利用制限を組み合わせることで、ガバナンス面も安心。金額の変更は即時反映。また、カードを紛失した場合、管理画面からカードの停止が可能。
              </p>
            </div>
          </div>
          <div css={FunctionWrapperInnerStyle}>
            <div css={FunctionImageWrapperStyle}>
              <StaticImage
                src='../../images/function/cashing-details.png'
                alt='リアルタイムに利用明細を反映'
                quality={100}
                placeholder='none'
                objectFit='contain'
                css={{
                  maxWidth: 580,
                  boxShadow: '0px 1.8px 8.1px 0px #00000040',
                }}
              />
            </div>
            <div css={FunctionDescriptionWrapperStyle}>
              <div css={FunctionHeadingStyle}>
                <h3>
                  <strong>リアルタイムに</strong>
                  <br />
                  利用明細を反映
                </h3>
              </div>
              <p>
                どのカードが、いつ、どこで、いくら決済したかの利用明細はリアルタイムで把握が可能。オンライン管理画面上への反映はもちろん、管理者へのメール通知もすべてリアルタイム。
              </p>
            </div>
          </div>
          <div css={FunctionWrapperInnerStyle}>
            <div css={FunctionImageWrapperStyle}>
              <StaticImage
                src='../../images/function/sass-management.png'
                alt='利用中のSaaSなど定期支払いを可視化'
                quality={100}
                placeholder='none'
                objectFit='contain'
                css={{
                  maxWidth: 580,
                  boxShadow: '0px 1.8px 8.1px 0px #00000040',
                }}
              />
            </div>
            <div css={FunctionDescriptionWrapperStyle}>
              <div css={FunctionHeadingStyle}>
                <h3>
                  利用中のSaaSなど
                  <br />
                  <strong>定期支払いを可視化</strong>
                </h3>
              </div>
              <p>
                利用しているサービスやツールを一覧で把握でき、不要な支払いの削減につながる。またカードごとに専用メールアドレスが発行されるため、領収書が添付されたメールの転送先として設定しておくだけで、電子帳簿保存法に準拠した形で領収書が自動で保存される。
              </p>
            </div>
          </div>
        </div>
        <Link
          to='/feature/'
          css={{
            alignSelf: 'center',
            width: '100%',
            maxWidth: 335,
            marginTop: 40,
          }}
        >
          <PaildButton arrow background={colors.primary}>
            機能一覧を見る
          </PaildButton>
        </Link>
      </div>
    </section>
  );
};

const FunctionSectionBackgroundStyle = css({
  position: 'absolute',
  top: -686,
  left: 0,
  width: '100%',
  height: 4300,
  zIndex: -1,
  overflow: 'hidden',

  '@media(max-width: 1119px)': {
    top: 0,
    height: 'calc(100% - 120px)',
    background: colors.secondary,
    overflow: 'visible',
  },
});

const FunctionSectionStyle = css({
  padding: '80px 0',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',

  '@media(max-width: 1119px)': {
    padding: '40px 0',
  },
});

const FunctionSectionInnerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 60,
  width: '100%',
  maxWidth: 1120,
  padding: '0 17px',

  '@media(max-width: 1119px)': {
    maxWidth: 640,
    gap: 30,
  },

  '@media(max-width: 768px)': {
    maxWidth: 480,
  },
});

const FunctionSectionHeadingStyle = css({
  h2: {
    fontSize: 48,
    padding: '24px 0 40px',
  },

  p: {
    fontSize: 16,
    color: colors.primary,
    fontWeight: 500,
    letterSpacing: 0.8,
  },

  '@media(max-width: 1119px)': {
    h2: {
      fontSize: 28,
      padding: '20px 0 30px',
    },

    p: {
      fontSize: 14,
    },
  },
});

const FunctionWrapperStyle = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 160,

  '@media(max-width: 1119px)': {
    gap: 80,
  },
});

const FunctionWrapperInnerStyle = css({
  display: 'flex',
  gap: 80,

  '@media(max-width: 1119px)': {
    gap: 30,
  },

  '@media(max-width: 768px)': {
    flexDirection: 'column',
  },
});

const FunctionDescriptionWrapperStyle = css({
  paddingTop: 24,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: 32,

  '@media(max-width: 1119px)': {
    paddingTop: 0,
    gap: 16,
  },
});

const FunctionHeadingStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,

  h3: {
    color: colors.black,
    fontSize: 40,

    '@media(max-width: 1119px)': {
      fontSize: 22,
    },
  },

  p: {
    color: colors.primary,
    fontSize: 16,
    lineHeight: 1,

    '@media(max-width: 1119px)': {
      fontSize: 14,
    },
  },

  strong: { color: colors.primary },
});

const FunctionImageWrapperStyle = css({
  minWidth: 580,

  '@media(max-width: 1119px)': {
    minWidth: 335,
  },
});
