import { css } from '@emotion/react';
import { FC } from 'react';
import { Link } from 'gatsby';

import { colors } from '../../theme/colors';
import { PaildButton } from '../atoms/PaildButton';

export const TopConceptSection: FC = () => {
  return (
    <section css={ConceptSectionStyle}>
      <div css={ConceptInnerStyle}>
        <h2 css={ConceptHeadingStyle}>
          <strong>ペイルド。</strong>
          <br />
          それは、新しい法人カードから
          <br />
          広がるスムーズな働き方。
        </h2>
        <div>
          <p css={ConceptDescriptionStyle}>
            もっとカンタンに、もっとシンプルに法人カードを使えるのはもちろんのこと、領収書の管理や仕訳まで改善します。
          </p>
          <p css={ConceptDescriptionStyle}>
            カードを使う側も、管理する側も、みんなpaildを通じて働きやすくなります。
          </p>
          <p css={ConceptDescriptionStyle}>
            また、システムを導入して終わり、そうはさせません。導入後も共に寄り添い、運用定着までサポートします。
          </p>
        </div>

        <Link
          to='/concept/'
          css={{ alignSelf: 'center', width: '100%', maxWidth: 335 }}
        >
          <PaildButton arrow background={colors.primary}>
            paildのコンセプト
          </PaildButton>
        </Link>
      </div>
    </section>
  );
};

const ConceptSectionStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
});

const ConceptInnerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: 1120,
  padding: '0 17px',
  position: 'relative',
  gap: 30,

  '@media(max-width: 1119px)': {
    maxWidth: 640,
    gap: 30,
  },

  '@media(max-width: 768px)': {
    maxWidth: 480,
  },
});

const ConceptDescriptionStyle = css({
  fontSize: 16,
  textAlign: 'center',
  lineHeight: 2,

  '@media(max-width: 1119px)': {
    fontSize: 14,
    textAlign: 'left',

    br: {
      display: 'none',
    },
  },
});

const ConceptHeadingStyle = css({
  fontSize: 36,
  textAlign: 'center',

  strong: { color: colors.primary, fontSize: 36, fontWeight: 700 },

  '@media(max-width: 1119px)': {
    fontSize: 28,
    strong: { fontSize: 28 },
  },
});
