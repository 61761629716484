import { css } from '@emotion/react';
import { FC } from 'react';

import { colors } from '../../theme/colors';
import { TopRegistrationFlow } from './TopRegistrationFlow';

export const FlowSection: FC = () => {
  return (
    <section css={FlowSectionStyle}>
      <div css={FloeSectionHeadingStyle}>
        <p>Flow</p>
        <h2>ご導入の流れ</h2>
      </div>
      <TopRegistrationFlow />
    </section>
  );
};

const FlowSectionStyle = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 1120,
  padding: '0 17px',
  position: 'relative',

  '@media(max-width: 1119px)': {
    maxWidth: 640,
    gap: 30,
  },

  '@media(max-width: 768px)': {
    maxWidth: 480,
  },
});

const FloeSectionHeadingStyle = css({
  h2: {
    fontSize: 48,
    padding: '24px 0 40px',
  },

  p: {
    fontSize: 16,
    color: colors.primary,
    fontWeight: 500,
    letterSpacing: 0.8,
  },

  '@media(max-width: 1119px)': {
    h2: {
      fontSize: 28,
      padding: '20px 0 30px',
    },

    p: {
      fontSize: 14,
    },
  },
});
