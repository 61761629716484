import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box } from '@mui/material';

const Wrapper = styled.div<{
  imgHeight?: number;
  wrapperWidth?: number;
  topWidth?: number;
  bottomWidth?: number;
  isOverSeven?: boolean;
}>`
  display: flex;
  align-items: ${({ isOverSeven }) => (isOverSeven ? 'flex-start' : 'center')};
  width: ${({ isOverSeven, wrapperWidth }) =>
    isOverSeven ? `${wrapperWidth! * 80}px` : '100%'};
  height: 80px;
  max-width: ${({ isOverSeven }) => (isOverSeven ? 'none' : '120rem')};
  margin: 0 auto;

  @keyframes loop {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  ul {
    max-height: 80px;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
    list-style: none;
    ${({ isOverSeven }) =>
      isOverSeven ? 'animation: loop 15s infinite linear' : 'none'};

    li {
      div {
        height: 80px;
      }
    }

    padding-right: ${({ isOverSeven }) => (isOverSeven ? '2rem' : '0')};
  }

  .pc {
    display: flex;
  }

  .sp {
    display: none;
  }

  @media (max-width: 960px) {
    width: ${({ isOverSeven, wrapperWidth }) =>
      isOverSeven ? `${wrapperWidth! * 60}px ` : '100%'};
    height: auto;
    flex-direction: ${({ isOverSeven }) => (isOverSeven ? 'row' : 'column')};
    justify-content: center;
    margin-top: 2rem;
    padding: 0 2rem;

    .pc {
      display: ${({ isOverSeven }) => (isOverSeven ? 'flex' : 'none')};
      padding-right: ${({ isOverSeven }) => (isOverSeven ? '1.2rem' : '0')};
      gap: 1.2rem;

      li {
        div {
          max-height: 60px;
        }
      }
    }

    .spWrapper {
    }

    .sp {
      display: flex;
      padding-right: ${({ isOverSeven }) => (isOverSeven ? '2rem' : '0')};

      display: flex;
      gap: 1.2rem;
      max-height: 72px;

      li {
        div {
          max-height: 72px;
          height: ${({ imgHeight }) => `calc((100vw - 56px) / ${imgHeight})`};
        }
      }
    }

    .first {
      width: 100%;
      height: ${({ imgHeight }) => `calc((100vw - 56px) / ${imgHeight})`};
      aspect-ratio: ${({ bottomWidth }) => `${bottomWidth} / 72`};
    }

    .second {
      width: 100%;
      height: ${({ imgHeight }) => `calc((100vw - 56px) / ${imgHeight})`};
      aspect-ratio: ${({ bottomWidth }) => `${bottomWidth} / 72`};
    }
  }

  @media (max-width: 480px) {
    width: ${({ isOverSeven, wrapperWidth }) =>
      isOverSeven ? `${wrapperWidth! * 48}px ` : '100%'};

    .pc {
      display: ${({ isOverSeven }) => (isOverSeven ? 'flex' : 'none')};

      li {
        div {
          max-height: 48px;
        }
      }
    }
  }
`;

export const ClientLogoList = () => {
  const data = useStaticQuery<GatsbyTypes.ClientLogoListQueryQuery>(graphql`
    query ClientLogoListQuery {
      contentfulSetting {
        clientLogoListWithSpacing {
          gatsbyImageData(placeholder: NONE, height: 80)
          title
        }
      }
    }
  `);

  const logoList = data.contentfulSetting?.clientLogoListWithSpacing;

  const logoListTop = logoList?.slice(0, Math.floor(logoList.length / 2));
  const logoListBottom = logoList?.slice(
    Math.floor(logoList.length / 2),
    logoList.length
  );

  const LogoListWrapper = ({ logoLength }: { logoLength: number }) => {
    // 8社以上でスクロールするアニメーションを追加するので要素数を増やしています。
    const mapNumberArray = [...Array(logoLength > 7 ? 3 : 1)];
    return (
      <>
        {mapNumberArray.map((_, i) => {
          return (
            <ul className='pc' key={`logo-list-wrapper-ul-pc-${i}`}>
              {logoList?.map((logo, j) => {
                return (
                  <li key={`logo-list-wrapper-li-${j}`}>
                    {logo && (
                      <GatsbyImage
                        image={logo.gatsbyImageData!}
                        alt={logo.title!}
                        objectFit='contain'
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          );
        })}
      </>
    );
  };

  const LogoListWrapperSp = ({ logoLength }: { logoLength: number }) => {
    // 7社までは2段に並べて表示していますが、7社以上で並びを1段にしているのでPCと共通化しています。スマホ版は非表示。
    return logoLength > 7 ? (
      <></>
    ) : (
      <div className='spWrapper'>
        <ul className='sp first'>
          {logoListTop?.map((logo, i) => {
            return (
              <li key={i}>
                {logo && (
                  <GatsbyImage
                    image={logo.gatsbyImageData!}
                    alt={logo.title!}
                    objectFit='contain'
                  />
                )}
              </li>
            );
          })}
        </ul>
        <ul className='sp second'>
          {logoListBottom?.map((logo, i) => {
            return (
              <li key={i}>
                {logo && (
                  <GatsbyImage
                    image={logo.gatsbyImageData!}
                    alt={logo.title!}
                    objectFit='contain'
                  />
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const logoWidthOneLine = logoList?.reduce((total, logo) => {
    return (
      total + logo?.gatsbyImageData?.width! / logo?.gatsbyImageData?.height!
    );
  }, 0);

  const topWidthTotal = logoListTop?.reduce((total, logo) => {
    return (
      total + logo?.gatsbyImageData?.width! / logo?.gatsbyImageData?.height!
    );
  }, 0);

  const bottomWidthTotal = logoListBottom?.reduce((total, logo) => {
    return (
      total + logo?.gatsbyImageData?.width! / logo?.gatsbyImageData?.height!
    );
  }, 0);

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Wrapper
        imgHeight={
          bottomWidthTotal! > topWidthTotal!
            ? bottomWidthTotal!
            : topWidthTotal!
        }
        wrapperWidth={(logoWidthOneLine! + logoList!.length / 4) * 3}
        topWidth={topWidthTotal! * 72 + (logoListTop!.length - 1) * 12}
        bottomWidth={bottomWidthTotal! * 72 + (logoListBottom!.length - 1) * 12}
        isOverSeven={logoList!.length > 7}
      >
        <LogoListWrapper logoLength={logoList!.length} />
        <LogoListWrapperSp logoLength={logoList!.length} />
      </Wrapper>
    </Box>
  );
};
