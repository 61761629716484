import { css } from '@emotion/react';
import { FC, ReactNode } from 'react';

import { colors } from '../../theme/colors';
import { PaildButton } from '../atoms/PaildButton';
import { Link } from 'gatsby';

import flow1 from '../../images/registration-flow/flow-1.svg';
import flow2 from '../../images/registration-flow/flow-2.svg';
import flow3 from '../../images/registration-flow/flow-3.svg';
import flow4 from '../../images/registration-flow/flow-4.svg';
import flow5 from '../../images/registration-flow/flow-5.svg';

type Props = {};

type Step = {
  title: string;
  shownTitle: ReactNode;
  description: string;
  img: string;
  link?: {
    text: string;
    url: string;
  };
};

const steps: Step[] = [
  {
    title: 'お問い合わせ',
    shownTitle: '問い合わせ',
    description: 'ご質問やご相談はお気軽にお問い合わせください。',
    img: flow1,
    link: {
      text: 'お問い合わせ',
      url: '/contact/',
    },
  },
  {
    title: 'ウォレット開設',
    shownTitle: (
      <>
        ウォレット
        <br />
        開設
      </>
    ),
    description: '申込みフォーム送信後に開設ご案内メールが届きます。',
    img: flow2,
    link: {
      text: '申込み',
      url: '/registration/',
    },
  },
  {
    title: '本人確認',
    shownTitle: '本人確認',
    description:
      '法人の本人確認を行うため、オンライン上で情報を入力して申請してください。登記簿原本など書類の提出は不要です。',
    img: flow3,
  },
  {
    title: '初期費用のお支払い',
    shownTitle: (
      <>
        初期費用の
        <br />
        お支払い
      </>
    ),
    description:
      '初期費用の入金完了後、本人確認コードが記載されたハガキが郵送されます。コード入力をもって本人確認が完了します。',
    img: flow4,
  },
  {
    title: 'チャージ＆カード発行',
    shownTitle: (
      <>
        チャージ＆
        <br />
        カード発行
      </>
    ),
    description:
      '管理画面からウォレットへのチャージやカード発行ができます。またカードごとに利用制限をかけることも可能です。',
    img: flow5,
  },
];

export const TopRegistrationFlow: FC<Props> = () => {
  return (
    <div css={RegistrationFlowStyle}>
      <ul css={FlowWrapperStyle}>
        {steps.map((step, index) => (
          <StepListItem step={step} index={index} key={index} />
        ))}
      </ul>
    </div>
  );
};

type StepProps = {
  step: Step;
  index: number;
};

const StepListItem: FC<StepProps> = (props) => {
  const listCss =
    props.index === 0
      ? [FlowStyle, BottomLine]
      : props.index === steps.length - 1
      ? [FlowStyle, TopLine]
      : [FlowStyle, BottomLine, TopLine];

  return (
    <li css={listCss}>
      <div css={StepStyle}>
        <p css={{ fontSize: 11 }}>step</p>
        <p css={{ fontSize: 22 }}>{props.index + 1}</p>
      </div>
      <div css={FlowDescriptionStyle}>
        <img src={props.step.img} alt={props.step.title} css={ImageStyle} />
        <p css={FlowTitleStyle}>{props.step.shownTitle}</p>
        <div css={DescriptionWithButtonStyle}>
          <p>{props.step.description}</p>
          {props.step.link && (
            <>
              <Link
                to={props.step.link.url}
                css={[
                  ButtonStyle,
                  { '@media(max-width: 1119px)': { display: 'none' } },
                ]}
              >
                <PaildButton outlined arrow maxWidth='100%' size='s'>
                  {props.step.link.text}
                </PaildButton>
              </Link>
              <Link
                to={props.step.link.url}
                css={[
                  ButtonStyle,
                  { '@media(min-width: 1120px)': { display: 'none' } },
                ]}
              >
                <PaildButton outlined arrow maxWidth='100%'>
                  {props.step.link.text}
                </PaildButton>
              </Link>
            </>
          )}
        </div>
      </div>
    </li>
  );
};

const RegistrationFlowStyle = css({
  display: 'flex',
  gap: 30,

  '@media(max-width: 1119px)': {
    flexDirection: 'column',
  },
});

const FlowWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  gap: 40,
  background: colors.secondary,
  borderRadius: 16,
  padding: '30px 20px 40px',
  listStyle: 'none',

  '@media(max-width: 1119px)': {
    flexDirection: 'column',
  },
});

const FlowStyle = css({
  width: '100%',
  maxWidth: 160,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 30,

  '@media(max-width: 1119px)': {
    flexDirection: 'row',
    maxWidth: '100%',
    gap: 20,
  },
});

const StepStyle = css({
  minWidth: 80,
  height: 80,
  borderRadius: '50%',
  border: `2px solid ${colors.black}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: colors.white,

  p: { fontWeight: 500 },

  '@media(max-width: 1119px)': {
    alignSelf: 'flex-start',
  },
});

const ImageStyle = css({
  width: '100%',
  height: 100,
  minWidth: 120,
  maxWidth: 120,
  maxHeight: 100,
});

const FlowTitleStyle = css({
  width: '100%',
  minWidth: 100,
  maxWidth: 100,
  minHeight: 58,
  fontSize: 20,
  fontWeight: 700,
  textAlign: 'center',
  color: colors.primary,

  '@media(max-width: 1119px)': {
    minHeight: 0,
  },

  '@media(max-width: 768px)': {
    maxWidth: '100%',
    br: {
      display: 'none',
    },
  },
});

const FlowDescriptionStyle = css({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 30,

  '@media(max-width: 1119px)': {
    flexDirection: 'row',
    gap: 20,
  },

  '@media(max-width: 768px)': {
    flexDirection: 'column',
  },
});
const DescriptionWithButtonStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
});

const ButtonStyle = css({
  width: '100%',
});

const BottomLine = css({
  position: 'relative',

  '&:after': {
    content: '""',
    position: 'absolute',
    background: colors.black,
    height: 2,
    width: 'calc((100% - 80px) / 2 + 20px)',
    top: 39,
    left: 'calc((100% - 80px) / 2 + 80px)',

    '@media(max-width: 1119px)': {
      width: 2,
      height: 'calc((100% - 80px) + 20px)',
      left: 39,
      top: 80,
    },
  },
});

const TopLine = css({
  position: 'relative',

  '&:before': {
    content: '""',
    position: 'absolute',
    background: colors.black,
    height: 2,
    width: 'calc((100% - 80px) / 2 + 20px)',
    top: 39,
    right: 'calc((100% - 80px) / 2 + 80px)',

    '@media(max-width: 1119px)': {
      top: 'auto',
      width: 2,
      height: 20,
      left: 39,
      bottom: '100%',
    },
  },
});
