import { css } from '@emotion/react';
import { FC, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, Link, useStaticQuery } from 'gatsby';

import { UserstoryCard } from '../userstory/UserstoryCard';
import { colors } from '../../theme/colors';
import { PaildButton } from '../atoms/PaildButton';
import { ButtonArrowIcon } from '../icons/ButtonArrowIcon';

export const UserstorySection: FC = () => {
  const data = useStaticQuery<GatsbyTypes.UserstorySectionQueryQuery>(graphql`
    query UserstorySectionQuery {
      allContentfulUserstory {
        nodes {
          id
          slug
          title
          intervieweeImage {
            title
            description
            gatsbyImageData(placeholder: NONE)
          }
          companyName
          companyIndustry
          companyEmployee
          category
          priority
        }
      }
    }
  `);

  // userstoryのリストを使いやすい形にmapしてからpriority順に並び替え
  const allUserstoryList = data.allContentfulUserstory.nodes
    .map((node) => {
      return {
        slug: node.slug,
        intervieweeImage: node.intervieweeImage?.gatsbyImageData,
        companyName: node.companyName,
        title: node.title,
        companyEmployee: node.companyEmployee,
        companyIndustry: node.companyIndustry,
        category: node.category,
        priority: node.priority,
      };
    })
    .sort((a, b) => (a.priority! > b.priority! ? -1 : 1));

  const [cardNumber, setCardNumber] = useState(0);

  const plusCardNumber = () => {
    setCardNumber((prev) => prev + 1);
  };

  const minusCardNumber = () => {
    setCardNumber((prev) => prev - 1);
  };

  return (
    <section css={UserstorySectionStyle}>
      <div css={UserstoryBackgroundStyle}>
        <div css={{ position: 'relative', width: '100%', height: '100%' }}>
          <StaticImage
            src='../../images/top-view/background/background3.svg'
            alt='background3'
            placeholder='none'
            objectFit='contain'
            css={{
              position: 'absolute',
              bottom: 0,
              transform: 'translate(-50%, 0)',
              left: '50%',
              minWidth: 3292,
            }}
          />
        </div>
      </div>
      <div css={UserstoryWrapperStyle}>
        <div css={UserstoryInnerStyle}>
          <div css={FloeSectionHeadingWrapperStyle}>
            <div css={FloeSectionHeadingStyle}>
              <p>Userstory</p>
              <h2>導入事例</h2>
            </div>
            <div css={ButtonWrapper}></div>
          </div>

          <div css={UserstoryCardWrapperStyle}>
            <ul css={UserstoryCardList(cardNumber, allUserstoryList.length)}>
              {allUserstoryList
                .slice(allUserstoryList.length - 3, allUserstoryList.length)
                .map((node, index) => {
                  return <UserstoryCard key={index} node={node} isTop />;
                })}
              {allUserstoryList.map((node, index) => {
                return <UserstoryCard key={index} node={node} isTop />;
              })}
              {allUserstoryList.slice(0, 3).map((node, index) => {
                return <UserstoryCard key={index} node={node} isTop />;
              })}
            </ul>
            <button
              css={[ButtonStyle, FirstPaginationStyle]}
              onClick={minusCardNumber}
            >
              <ButtonArrowIcon />
            </button>
            <button
              css={[ButtonStyle, LastPaginationStyle]}
              onClick={plusCardNumber}
            >
              <ButtonArrowIcon />
            </button>
          </div>

          <Link
            to='/userstory/'
            css={{ alignSelf: 'center', width: '100%', maxWidth: 335 }}
          >
            <PaildButton arrow background={colors.primary}>
              導入事例一覧を見る
            </PaildButton>
          </Link>
        </div>
      </div>
    </section>
  );
};

const UserstoryBackgroundStyle = css({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: 1990,
  zIndex: -1,
  overflow: 'hidden',
});

const UserstorySectionStyle = css({
  padding: '60px 0',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
});

const UserstoryWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
});

const UserstoryInnerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 1120,
  padding: '0 17px',
  position: 'relative',
  gap: 50,

  '@media(max-width: 1119px)': {
    maxWidth: 640,
    gap: 30,
  },

  '@media(max-width: 768px)': {
    maxWidth: 480,
  },
});

const FloeSectionHeadingWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const FloeSectionHeadingStyle = css({
  h2: {
    fontSize: 48,
    padding: '24px 0 0',
  },

  p: {
    fontSize: 16,
    color: colors.primary,
    fontWeight: 500,
    letterSpacing: 0.8,
  },

  '@media(max-width: 1119px)': {
    h2: {
      fontSize: 28,
      padding: '20px 0 0',
    },

    p: {
      fontSize: 14,
    },
  },
});

const UserstoryCardWrapperStyle = css({
  width: '100%',
  height: 480,
  position: 'relative',

  '@media(max-width: 768px)': {
    maxWidth: 335,
    alignSelf: 'center',
  },
});

const UserstoryCardList = (cardNumber: number, length: number) =>
  css({
    display: 'flex',
    gap: 40,
    width: 'max-content',

    position: 'absolute',
    top: 0,
    left: cardNumber * -375 - 1125,
    transition: 'left 0.3s',
    listStyle: 'none',
    transform: `translateX(${
      (335 * length + 40 * length) * Math.floor(cardNumber / length)
    }px)`,

    '@media(max-width: 1119px)': {
      gap: 30,
      left: cardNumber * -318 - 954,
      transform: `translateX(${
        (288 * length + 30 * length) * Math.floor(cardNumber / length)
      }px)`,
    },

    '@media(max-width: 768px)': {
      paddingLeft: 23.5,
    },
  });

const ButtonWrapper = css({
  display: 'flex',
  gap: 12,
});

const ButtonStyle = css({
  position: 'absolute',
  top: 100,
  width: 62,
  height: 62,
  borderRadius: '50%',
  border: `solid 2px ${colors.primary}`,

  background: colors.white,
  color: colors.primary,
  transition: '0.3s',

  '&:hover': {
    background: colors.primary,
    color: colors.white,

    '& .arrowStroke': { stroke: colors.white },
  },

  '@media(max-width: 1119px)': {
    top: 78,
  },

  '@media(max-width: 768px)': {
    width: 48,
    height: 48,
  },
});

const FirstPaginationStyle = css({
  left: -64,
  display: 'grid',
  placeItems: 'center',
  transform: 'rotate(180deg)',
  '&:hover': {
    paddingLeft: 10,
  },
  '& .arrowStroke': {
    stroke: colors.primary,
  },

  '@media(max-width: 768px)': {
    left: -12,
  },
});

const LastPaginationStyle = css({
  right: -64,
  display: 'grid',
  placeItems: 'center',
  '&:hover': {
    paddingLeft: 10,
  },
  '& .arrowStroke': {
    stroke: colors.primary,
  },

  '@media(max-width: 768px)': {
    right: -12,
  },
});
