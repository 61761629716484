import { HeadInner } from '../components/Head';
import { Layout } from '../components/layout';

import { FirstView } from '../components/topView/FirstView';
import { LogoSection } from '../components/topView/LogoSection';
import { TopSection } from '../components/topView/TopSection';
import { MeritSection } from '../components/topView/MeritSection';
import { TopFunctionSection } from '../components/topView/TopFunctionSection';
import { TopConceptSection } from '../components/topView/TopConceptSection';
import { FlowSection } from '../components/topView/FlowSection';
import { UserstorySection } from '../components/topView/UserstorySection';
import { FAQSection } from '../components/topView/FAQSection';
import { NewsSeminarSection } from '../components/topView/NewsSeminarSection';

export const Head = () => (
  <HeadInner
    path='/'
    description='paild（ペイルド）は、自腹立替、小口現金、仮払いをなくし、法人支出の最適化を実現します。法人カード、経費精算、領収書管理、仕訳、会計ソフト連携、電子帳簿保存法、インボイス制度に対応'
  />
);

const IndexPage = () => {
  return (
    <Layout>
      <FirstView />
      <LogoSection />
      <TopSection />
      <MeritSection />
      <TopConceptSection />
      <TopFunctionSection />
      <FlowSection />
      <UserstorySection />
      <FAQSection />
      <NewsSeminarSection />
    </Layout>
  );
};

export default IndexPage;
