import { css } from '@emotion/react';
import { FC } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { colors } from '../../theme/colors';

import { Link } from 'gatsby';
import { PaildButton } from '../atoms/PaildButton';

export const TopSection: FC = () => {
  return (
    <section css={TopSectionStyle}>
      <div css={TopSectionBackgroundStyle}>
        <div css={{ position: 'relative', width: '100%', height: '100%' }}>
          <StaticImage
            src='../../images/top-view/background/background1.svg'
            alt='background1'
            placeholder='none'
            objectFit='contain'
            css={{ position: 'absolute', top: 0, right: 0 }}
          />
        </div>
      </div>
      <h2 css={TopSectionHeadingStyle}>
        法人支出の最適化を実現する
        <br />
        <strong>paild（ペイルド）</strong>
      </h2>
      <div css={TopSectionInnerStyle}>
        <div css={TopFeatureWrapperStyle()}>
          <StaticImage
            src='../../images/top-view/cost.svg'
            alt='従業員へのカード配布で経費の立替が不要'
            quality={100}
            placeholder='none'
            objectFit='contain'
          />
          <div css={TopFeatureDescriptionWrapperStyle}>
            <h3>
              従業員へのカード配布で
              <br />
              経費の立替が不要
            </h3>
            <p css={TopFeatureDescriptionTextStyle}>
              社員一人一人にpaildカードを配布することで、
              <br />
              これまで自腹で立て替えていた経費が不要に。
            </p>
          </div>
        </div>
        <div css={TopFeatureWrapperStyle(true)}>
          <StaticImage
            src='../../images/top-view/cashless.svg'
            alt='キャッシュレス化で小口現金の管理から解放'
            quality={100}
            placeholder='none'
            objectFit='contain'
          />
          <div css={TopFeatureDescriptionWrapperStyle}>
            <h3>
              キャッシュレス化で
              <br />
              小口現金の管理から解放
            </h3>
            <p css={TopFeatureDescriptionTextStyle}>
              店舗ごとに用意していた小口現金の代わりにpaildカードを配布。
              <br />
              現金の照合作業や送金の手間が不要に。
            </p>
          </div>
        </div>
        <div css={TopFeatureWrapperStyle()}>
          <StaticImage
            src='../../images/top-view/receipt.svg'
            alt='領収書の回収・提出・保管の負担を削減'
            quality={100}
            placeholder='none'
            objectFit='contain'
          />
          <div css={TopFeatureDescriptionWrapperStyle}>
            <h3>
              領収書の回収・提出・管理の
              <br
                css={{
                  '@media(max-width: 768px)': {
                    display: 'none',
                  },
                }}
              />
              負担を削減
            </h3>
            <p css={TopFeatureDescriptionTextStyle}>
              電子帳簿保存法対応だから領収書やレシートの紙での保存は不要。メールによる領収書の回収機能搭載で、スマートフォンだけでも領収書提出が完結。
            </p>
            <div css={JiimaWrapper}>
              <div css={JiimaLogoWrapper}>
                <StaticImage
                  src='../../images/top-view/電子取引ソフト【R3】黒L.jpg'
                  alt='電子取引ソフト'
                  quality={100}
                  placeholder='none'
                  objectFit='contain'
                  css={JiimaLogoStyle}
                />
                <StaticImage
                  src='../../images/top-view/スキャナ保存ソフト【R3】黒L.jpg'
                  alt='スキャナ保存ソフト'
                  quality={100}
                  placeholder='none'
                  objectFit='contain'
                  css={JiimaLogoStyle}
                />
              </div>

              <p>
                電子取引ソフト法的要件認証」の認証ロゴは公益社団法人
                日本文書情報マネジメント協会によりライセンスされています。
              </p>
            </div>
          </div>
        </div>
        <div css={TopFeatureWrapperStyle(true)}>
          <StaticImage
            src='../../images/top-view/invoice.svg'
            alt='インボイス制度に対応したAI-OCR、仕訳入力が可能'
            quality={100}
            placeholder='none'
            objectFit='contain'
          />
          <div css={TopFeatureDescriptionWrapperStyle}>
            <h3>
              AI-OCR搭載で
              <br />
              インボイス制度にもスムーズに対応
            </h3>
            <p css={TopFeatureDescriptionTextStyle}>
              領収書の情報をすべてAI-OCRによって自動で読み取り、さらに適格事業者番号を国税庁のデータベースと自動照合。その後の仕訳入力もスムーズに。
            </p>
            <Link
              to='/feature/invoice'
              css={{ display: 'flex', justifyContent: 'center' }}
            >
              <PaildButton outlined arrow>
                詳しくはこちら
              </PaildButton>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

const TopSectionBackgroundStyle = css({
  position: 'absolute',
  top: -200,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: -1,
  overflow: 'hidden',
});

const TopSectionStyle = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 60,
  position: 'relative',
});

const TopSectionInnerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 100,
  width: '100%',
  maxWidth: 1120,
  padding: '0 17px',

  '@media(max-width: 1119px)': { gap: 30 },
});

const TopSectionHeadingStyle = css({
  fontSize: 28,
  textAlign: 'center',

  strong: {
    color: colors.primary,
    fontSize: 58,
    fontWeight: 700,
  },

  '@media(max-width: 1119px)': {
    fontSize: 28,
  },

  '@media(max-width: 768px)': {
    fontSize: 16,
    textAlign: 'center',
    maxWidth: 480,
    strong: { fontSize: 28 },
  },
});

const TopFeatureWrapperStyle = (isAlt?: boolean) =>
  css({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: isAlt ? 'row-reverse' : 'row',
    gap: 100,

    '@media(max-width: 1119px)': {
      gap: 40,
      padding: '30px 17px',
      maxWidth: 640,
    },

    '@media(max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  });

const TopFeatureDescriptionWrapperStyle = css({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 30,
  width: '100%',
  maxWidth: 560,

  h3: {
    fontSize: 34,
  },

  '@media(max-width: 1119px)': {
    maxWidth: 373,

    h3: {
      fontSize: 24,
    },
  },
});

const TopFeatureDescriptionTextStyle = css({
  fontSize: 16,
  lineHeight: 2,

  '@media(max-width: 1119px)': {
    fontSize: 14,
  },
});

const JiimaWrapper = css({
  display: 'flex',
  gap: 16,
  alignItems: 'center',

  p: {
    color: colors.gray,
    fontSize: 12,
  },

  '@media(max-width: 1119px)': {
    flexDirection: 'column',
  },
});

const JiimaLogoWrapper = css({
  display: 'flex',
  gap: 16,
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  maxWidth: 256,
});

const JiimaLogoStyle = css({
  width: '100%',
  maxWidth: 80,
});
