import { css } from '@emotion/react';
import { FC } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { colors } from '../../theme/colors';
import { PaildButton } from '../atoms/PaildButton';

export const FirstView: FC = () => {
  return (
    <section css={FirstViewStyle}>
      <div css={TopBackgroundStyle} />
      <div css={DecoWrapperStyle}>
        <StaticImage
          src='../../images/top-view/first-view/first-view-deco-01.svg'
          alt='first-view-deco-01'
          quality={100}
          placeholder='none'
          objectFit='contain'
          css={{
            position: 'absolute',
            top: 144,
            left: 'calc(50% - 984px)',
            '@media(max-width: 1119px)': {
              top: 335,
              left: 'calc(50% - 280px)',
              width: 80,
            },
            '@media(max-width: 768px)': {
              top: 450,
              left: -16,
              width: 80,
            },
          }}
        />
        <StaticImage
          src='../../images/top-view/first-view/first-view-deco-02.svg'
          alt='first-view-deco-02'
          quality={100}
          placeholder='none'
          objectFit='contain'
          css={{
            position: 'absolute',
            bottom: 32,
            right: 'calc(50% - 900px)',
            '@media(max-width: 1119px)': {
              display: 'none',
            },
          }}
        />
      </div>
      <div css={FirstViewWrapperStyle}>
        <div css={FirstViewInnerStyle}>
          <h1 css={FirstViewHeadingStyle}>
            <strong>新しい法人カード</strong>から
            <br
              css={{
                display: 'none',
                '@media(max-width: 1119px)': {
                  display: 'inline',
                },
              }}
            />
            広がる
            <br
              css={{
                '@media(max-width: 1119px)': {
                  display: 'none',
                },
              }}
            />
            スムーズな働き方
          </h1>
          <ul css={TopMessageWrapperStyle}>
            <li>・自腹立替・小口現金の管理から解放</li>
            <li>・充実したサポートで安心導入</li>
          </ul>
          <div css={CVButtonWrapperStyle}>
            <Link to='/request-document/' css={LinkButtonStyle}>
              <PaildButton arrow>資料請求</PaildButton>
            </Link>
            <Link to='/contact/' css={LinkButtonStyle}>
              <PaildButton outlined arrow>
                お問い合わせ
              </PaildButton>
            </Link>
          </div>
        </div>
      </div>

      <div css={HeroImageWrapperStyle}>
        <div css={HeroImageHiddenStyle}>
          <StaticImage
            src='../../images/top-view/first-view/hero.png'
            alt='hero_pc'
            quality={100}
            placeholder='none'
            objectFit='cover'
            objectPosition='left'
            loading='eager'
            css={HeroImageStyleCover}
          />
        </div>
      </div>
    </section>
  );
};

const FirstViewStyle = css({
  padding: '60px 17px 260px',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  position: 'relative',
  background: colors.secondary,

  '@media(max-width: 1119px)': {
    padding: '30px 17px',
    alignItems: 'center',
    gap: 20,
    marginBottom: 150,
  },

  '@media(max-width: 768px)': {
    flexDirection: 'column',
  },
});

const TopBackgroundStyle = css({
  position: 'absolute',
  left: 0,
  bottom: '100%',
  background: colors.secondary,
  width: '100%',
  height: 132,

  '@media(max-width: 1119px)': {
    height: 56,
  },
});

const DecoWrapperStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: 'calc(100% + 100px)',
  overflow: 'hidden',
});

const HeroImageWrapperStyle = css({
  position: 'absolute',
  width: '52%',
  top: 0,
  left: '48%',
  height: 760,

  '@media(max-width: 1119px)': {
    top: 0,
    height: 400,
  },

  '@media(max-width: 768px)': {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    width: '100%',
    maxWidth: 500,
    height: 200,
  },
});

const HeroImageHiddenStyle = css({
  width: '100%',
  height: 760,
  position: 'relative',
  overflow: 'hidden',

  '@media(max-width: 1119px)': {
    height: 500,
  },

  '@media(max-width: 768px)': {
    width: 'calc(100% + 17px)',
    height: 350,
  },
});

const HeroImageStyleCover = css({
  height: '100%',
  minWidth: 1083,

  '@media(max-width: 1119px)': {
    width: 700,
    minWidth: 0,
    height: 500,
  },

  '@media(max-width: 768px)': {
    display: 'block',
    position: 'absolute',
    top: 0,
    width: 500,
    minWidth: 500,
    height: 350,
  },
});

const FirstViewWrapperStyle = css({
  width: '100%',
  maxWidth: 1520,
  position: 'relative',

  '@media(max-width: 768px)': {
    maxWidth: 676,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const FirstViewInnerStyle = css({
  maxWidth: 732,
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 32,
  gap: 40,
  position: 'relative',
  zIndex: 5,

  '@media(max-width: 1119px)': {
    maxWidth: 400,
    paddingLeft: 0,
  },

  '@media(max-width: 768px)': {
    maxWidth: 335,
    alignItems: 'center',
  },
});

const FirstViewHeadingStyle = css({
  fontSize: 52,
  fontWeight: 700,
  lineHeight: 1.6,
  color: colors.black,
  strong: {
    fontWeight: 700,
    color: colors.primary,
  },

  '@media(max-width: 1119px)': {
    fontSize: 36,
  },

  '@media(max-width: 768px)': {
    alignSelf: 'flex-start',
    fontSize: 28,
    textAlign: 'center',
  },
});

const TopMessageWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  fontSize: 32,
  listStyle: 'none',
  fontWeight: 500,
  lineHeight: 1.8,
  zIndex: 5,

  '@media(max-width: 1119px)': {
    fontSize: 20,
  },

  '@media(max-width: 768px)': {
    fontSize: 16,
  },
});

const CVButtonWrapperStyle = css({
  paddingTop: 40,
  display: 'flex',
  gap: 16,

  '@media(max-width: 1119px)': {
    width: '100%',
    paddingTop: 0,
  },

  '@media(max-width: 768px)': {
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const LinkButtonStyle = css({
  flex: 1,
  maxWidth: 335,
  width: '100%',
});
