import { css } from '@emotion/react';
import { FC } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { colors } from '../../theme/colors';
import { SectionHeading } from '../atoms/SectionHeading';
import { OptimizationFlows } from '../organisms/OptimizationFlows';

export const MeritSection: FC = () => {
  return (
    <section css={MeritSectionStyle}>
      <SectionHeading maxWidth={252} lineHeight={1.4}>
        法人カードの
        <br />
        <strong>メリット</strong>
      </SectionHeading>
      <div css={MeritListStyle}>
        <div css={MeritFirstColumnStyle}>
          <p css={{ borderBottom: '1px solid #d9d9d9' }}>
            自腹で
            <br />
            立替
          </p>
          <p css={{ borderBottom: '1px solid #d9d9d9' }}>
            必要
            <br />
            書類
            <br />
            の提出
          </p>
          <p css={{ borderBottom: '1px solid #d9d9d9' }}>
            必要
            <br />
            書類
            <br />
            の確認
          </p>
          <p>
            各口座
            <br />
            への
            <br />
            振込
          </p>
        </div>
        <div css={MeritSecondColumnStyle}>
          <h3>
            法人カード
            <br />
            を持って
            <br />
            いない
          </h3>
          <p css={{ borderBottom: '1px solid #d9d9d9' }}>○</p>
          <p css={{ borderBottom: '1px solid #d9d9d9' }}>○</p>
          <p css={{ borderBottom: '1px solid #d9d9d9' }}>○</p>
          <p>○</p>
        </div>
        <div css={MeritThirdColumnStyle}>
          <h3>
            法人カード
            <br />
            を持って
            <br />
            いる
          </h3>
          <p css={{ borderBottom: '1px solid #d9d9d9' }}>必要なし！</p>
          <p css={{ borderBottom: '1px solid #d9d9d9', position: 'relative' }}>
            <span
              css={{
                position: 'absolute',
                top: 12,
                left: 0,
                width: '100%',
                height: 'calc(200% - 24px)',
                border: `3px dashed ${colors.primary}`,
              }}
            />
            ○
          </p>
          <p css={{ borderBottom: '1px solid #d9d9d9' }}>○</p>
          <p>必要なし！</p>
        </div>
        <div css={MeritSolutionWrapperStyle}>
          <p css={MeritSolutionStyle}>
            paildなら
            <br />
            ここも
            <br />
            スムーズに
          </p>
        </div>
      </div>
      <StaticImage
        src='../../images/top-view/merit-arrow.svg'
        alt='merit-arrow-bottom'
        quality={100}
        placeholder='none'
        objectFit='contain'
        css={{
          '@media(max-width: 768px)': {
            display: 'none',
          },
        }}
      />
      <div css={OptimizationWrapperStyle}>
        <h3 css={OptimizationBalloonRootStyle}>
          <p css={OptimizationBalloonStyle}>paildにおまかせ</p>
          <div css={OptimizationBalloonTriangleStyle} />
        </h3>
        <OptimizationFlows />
        <div css={OptimizationTextStyle}>
          <p>決済・領収書の回収管理・仕訳・会計ソフトの連携までスムーズに</p>
        </div>
      </div>
    </section>
  );
};

const MeritSectionStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 60,
  width: '100%',
  maxWidth: 1120,

  '@media(max-width: 1119px)': { gap: 30 },
});

const MeritListStyle = css({
  display: 'flex',
  gap: 16,

  '@media(max-width: 768px)': {
    gap: 6,
  },
});

const MeritFirstColumnStyle = css({
  width: 130,
  paddingTop: 72,

  p: {
    height: 80,
    display: 'grid',
    placeItems: 'center',
    whiteSpace: 'nowrap',
    fontWeight: 700,
    br: { display: 'none' },
  },

  '@media(max-width: 768px)': {
    width: 49,
    p: { fontSize: 12, br: { display: 'inline' } },
  },
});

const MeritSecondColumnStyle = css({
  border: `2px solid ${colors.black}`,
  borderRadius: 16,
  overflow: 'hidden',

  h3: {
    fontSize: 16,
    height: 72,
    display: 'grid',
    placeItems: 'center',
    whiteSpace: 'nowrap',
    color: colors.white,
    background: colors.gray,
    br: { display: 'none' },
  },

  p: {
    fontSize: 20,
    width: 210,
    height: 80,
    display: 'grid',
    placeItems: 'center',
    whiteSpace: 'nowrap',
    fontWeight: 700,
    margin: '0 30px',
  },

  '@media(max-width: 1119px)': {
    h3: {
      fontSize: 14,
    },
    p: {
      width: 140,
      fontSize: 16,
    },
  },

  '@media(max-width: 768px)': {
    h3: {
      br: { display: 'inline' },
    },
    p: { width: 94, margin: '0 8px' },
  },
});

const MeritThirdColumnStyle = css({
  border: `2px solid ${colors.black}`,
  borderRadius: 16,
  overflow: 'hidden',

  h3: {
    fontSize: 16,
    height: 72,
    display: 'grid',
    placeItems: 'center',
    whiteSpace: 'nowrap',
    color: colors.white,
    background: colors.primary,
    br: { display: 'none' },
  },

  p: {
    fontSize: 20,
    width: 210,
    height: 80,
    display: 'grid',
    placeItems: 'center',
    whiteSpace: 'nowrap',
    fontWeight: 700,
    margin: '0 30px',
  },

  '@media(max-width: 1119px)': {
    h3: {
      fontSize: 14,
    },
    p: {
      width: 140,
      fontSize: 16,
    },
  },
  '@media(max-width: 768px)': {
    h3: {
      br: { display: 'inline' },
    },
    p: {
      width: 94,
      margin: '0 8px',
    },
  },
});

const MeritSolutionWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  paddingTop: 72,
  '@media(max-width: 768px)': {
    width: 49,
    position: 'relative',
  },
});

const MeritSolutionStyle = css({
  display: 'grid',
  placeItems: 'center',
  textAlign: 'center',
  color: colors.primary,
  border: `2px solid ${colors.primary}`,
  outline: `2px solid ${colors.white}`,
  borderRadius: 100,
  width: 130,
  height: 130,
  background: colors.white,
  position: 'relative',
  fontWeight: 700,

  '&:before': {
    content: '""',
    position: 'absolute',
    width: 48,
    height: 2,
    background: colors.primary,
    right: '100%',

    '@media(max-width: 768px)': {
      display: 'none',
    },
  },

  '@media(max-width: 768px)': {
    position: 'absolute',
    width: 94,
    height: 94,
    right: 0,
  },
});

const OptimizationWrapperStyle = css({
  background: colors.secondary,
  borderRadius: 16,
  padding: '120px 60px 60px',
  width: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: 50,
  marginTop: 40,

  '@media(max-width: 1119px)': {
    padding: '80px 17px 40px',
    borderRadius: 0,
  },

  '@media(max-width: 768px)': {
    display: 'none',
  },
});

const OptimizationBalloonRootStyle = css({
  position: 'absolute',
  top: -40,
  left: '50%',
  transform: 'translate(-50%, 0)',
  width: '100%',
  maxWidth: 720,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '@media(max-width: 1119px)': {
    top: -30,
  },
});

const OptimizationBalloonStyle = css({
  width: '100%',
  position: 'relative',
  lineHeight: 1,
  background: colors.grad.secondary,
  color: colors.white,
  fontSize: 32,
  padding: 25,
  textAlign: 'center',
  borderRadius: 40,
  margin: 0,

  '@media(max-width: 1119px)': {
    maxWidth: 335,
    fontSize: 18,
    padding: 21,
  },
});

const OptimizationBalloonTriangleStyle = css({
  width: '100%',
  position: 'relative',
  background: colors.grad.secondary,
  height: 20,

  '&:before': {
    content: '""',
    display: 'block',
    width: '50%',
    position: 'absolute',
    top: 0,
    left: 0,
    borderTop: 'none',
    borderRight: '20px solid transparent',
    borderBottom: `20px solid ${colors.secondary}`,
  },
  '&:after': {
    content: '""',
    display: 'block',
    width: '50%',
    position: 'absolute',
    top: 0,
    right: 0,
    borderTop: 'none',
    borderLeft: '20px solid transparent',
    borderBottom: `20px solid ${colors.secondary}`,
  },

  '@media(max-width: 1119px)': {
    maxWidth: 335,
  },
});

const OptimizationTextStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  p: {
    fontSize: 32,
    fontWeight: 700,
    color: colors.primary,
    br: {
      display: 'none',
    },

    '@media(max-width: 1119px)': {
      fontSize: 28,
    },

    '@media(max-width: 768px)': {
      width: 320,
      br: {
        display: 'inline',
      },
    },
  },
});
