import { css } from '@emotion/react';
import { FC } from 'react';
import { Link, graphql, navigate, useStaticQuery } from 'gatsby';

import { NewsCard } from '../news/NewsCard';
import { PaildButton } from '../atoms/PaildButton';
import { colors } from '../../theme/colors';

export const NewsSeminarSection: FC = () => {
  const data = useStaticQuery<GatsbyTypes.NewsSectionQueryQuery>(graphql`
    query NewsSectionQuery {
      allContentfulServiceUpdate(
        sort: { fields: publishedAt, order: DESC }
        limit: 3
      ) {
        nodes {
          id
          slug
          cover {
            gatsbyImageData
          }
          description {
            description
          }
          title
          publishedAt
          metadata {
            tags {
              name
            }
          }
        }
      }
      allContentfulPressRelease(
        sort: { fields: publishedAt, order: DESC }
        limit: 3
      ) {
        nodes {
          id
          slug
          cover {
            gatsbyImageData
          }
          description {
            description
          }
          title
          publishedAt
          metadata {
            tags {
              name
            }
          }
        }
      }
      allContentfulInformation(limit: 3) {
        nodes {
          id
          slug
          cover {
            gatsbyImageData
          }
          description {
            description
          }
          title
          publishedAt
          metadata {
            tags {
              name
            }
          }
        }
      }
      allContentfulSeminar(
        sort: { fields: createdAt, order: DESC }
        filter: { isClosed: { ne: true } }
      ) {
        nodes {
          slug
          title
          image {
            gatsbyImageData
          }
        }
      }
      allContentfulVideo {
        nodes {
          title
          slug
          coverImage {
            gatsbyImageData
          }
          videoId
          priority
        }
      }
    }
  `);

  // 最新3つのアップデート
  const allServiceUpdates = data.allContentfulServiceUpdate.nodes.map(
    (updates) => ({ ...updates, type: 'updates' })
  );

  // 最新3つのプレスリリース
  const allPressRelease = data.allContentfulPressRelease.nodes.map((press) => ({
    ...press,
    type: 'press-release',
  }));

  // 最新3つのお知らせ
  const allInformation = data.allContentfulInformation.nodes.map((info) => ({
    ...info,
    type: 'info',
  }));

  // それぞれの最新3つのリストをpublishAt順に並び替え、その中から最新3つを表示
  const allUpdates = [
    ...allServiceUpdates,
    ...allPressRelease,
    ...allInformation,
  ]
    .sort((a, b) => (a.publishedAt! > b.publishedAt! ? -1 : 1))
    .slice(0, 3);

  // セミナーの中から最新3つを表示
  const allSeminarList = data.allContentfulSeminar.nodes
    .map((node) => {
      return {
        slug: node.slug!,
        title: node.title!,
        image: node.image?.gatsbyImageData!,
        type: 'seminar',
      };
    })
    .filter((node) => node.slug !== 'dummy' || null)
    .slice(0, 3);

  // ビデオの中から最新3つを表示
  const allVideoList = data.allContentfulVideo.nodes
    .map((node) => {
      return {
        slug: node.slug!,
        title: node.title!,
        videoId: node.videoId,
        image: node.coverImage?.gatsbyImageData!,
        priority: node.priority,
        type: 'video',
      };
    })
    .filter((node) => node.slug !== 'dummy' || null)
    .sort((a, b) => (a.priority! > b.priority! ? -1 : 1))
    .slice(0, 3);

  return (
    <section css={NewsSeminarSectionStyle}>
      <div css={ListWrapperStyle}>
        <h2 css={ListHeadingStyle}>お知らせ</h2>
        <ul>
          {allUpdates.map((update, index) => {
            return (
              <NewsCard
                key={index}
                node={update}
                isLast={index === allUpdates.length - 1}
                isTop
              />
            );
          })}
        </ul>
        <Link
          to='/news/'
          css={{ alignSelf: 'center', width: '100%', maxWidth: 335 }}
        >
          <PaildButton background={colors.primary} arrow>
            お知らせをもっと見る
          </PaildButton>
        </Link>
      </div>
      <div css={ListWrapperStyle}>
        <h2 css={ListHeadingStyle}>
          {allSeminarList.length > 0 ? 'セミナー' : 'セミナーアーカイブ動画'}
        </h2>
        <ul>
          {allSeminarList.length > 0 ? (
            <>
              {allSeminarList.map((seminar, index) => {
                return (
                  <NewsCard
                    key={index}
                    node={seminar}
                    isLast={index === allSeminarList.length - 1}
                    isTop
                  />
                );
              })}
              {[...Array(3 - allSeminarList.length)].map((_, index) => {
                return (
                  <div
                    key={index}
                    css={{
                      height: 161,
                      '@media(max-width: 1119px)': {
                        display: 'none',
                      },
                    }}
                  />
                );
              })}
            </>
          ) : (
            <>
              {allVideoList.map((video, index) => {
                return (
                  <NewsCard
                    key={index}
                    node={video}
                    isLast={index === allVideoList.length - 1}
                    isTop
                    onClick={() => {
                      navigate(`documents/${video.slug}`);
                    }}
                  />
                );
              })}
            </>
          )}
        </ul>
        <Link
          to='/seminar/'
          css={{ alignSelf: 'center', width: '100%', maxWidth: 335 }}
        >
          <PaildButton background={colors.primary} arrow>
            セミナーをもっと見る
          </PaildButton>
        </Link>
      </div>
    </section>
  );
};

const NewsSeminarSectionStyle = css({
  display: 'flex',
  width: '100%',
  maxWidth: 1120,
  padding: '0 17px',
  position: 'relative',
  gap: 60,

  '@media(max-width: 1119px)': {
    flexDirection: 'column',
    maxWidth: 640,
  },

  '@media(max-width: 768px)': {
    maxWidth: 480,
  },
});

const ListWrapperStyle = css({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: 40,

  '@media(max-width: 1119px)': {
    gap: 30,
  },
});

const ListHeadingStyle = css({
  fontSize: 34,
  whiteSpace: 'nowrap',

  '@media(max-width: 1119px)': {
    fontSize: 24,
  },
});
