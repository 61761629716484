import { css } from '@emotion/react';
import { FC } from 'react';

import { ClientLogoList } from '../ClientLogoList';
import { colors } from '../../theme/colors';

export const LogoSection: FC = () => {
  return (
    <section css={LogoSectionStyle}>
      <p>
        導入企業
        <br />
        <strong>
          <span>5,000</span>社
        </strong>
        突破
      </p>
      <ClientLogoList />
    </section>
  );
};

const LogoSectionStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  overflow: 'hidden',
  gap: 30,

  br: {
    display: 'none',
  },

  p: {
    display: 'flex',
    alignItems: 'flex-end',
    fontSize: 40,
    fontWeight: 700,

    strong: {
      display: 'flex',
      alignItems: 'flex-end',
      color: colors.primary,

      span: {
        display: 'block',
        fontSize: 60,
        marginLeft: 8,
        marginRight: 6,
        marginBottom: 2,
        lineHeight: 1,
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 700,
      },
    },
  },

  '@media(max-width: 1119px)': {
    gap: 10,
    p: {
      fontSize: 28,
      fontWeight: 700,

      strong: {
        span: {
          fontSize: 50,
        },
      },
    },
  },

  '@media(max-width: 768px)': {
    p: {
      textAlign: 'center',
      br: {
        display: 'inline',
      },
    },
  },
});
