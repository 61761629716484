import { css } from '@emotion/react';
import { FC, useState } from 'react';

import { colors } from '../../theme/colors';

const FAQList = [
  {
    question: '創業直後でも利用できますか？',
    answer:
      'はい。paildは与信審査がないプリペイド式のサービスになります。創業直後でも問題なくどなたでもご利用いただけます。',
  },
  {
    question: '発行枚数上限はありますか？',
    answer:
      '無制限でカード発行できます。\n※リアルカード発行手数料は30枚まで無料、それ以降は1枚1,400円（税抜）となります\n※各種パッケージプランご利用の場合は31枚以上も発行手数料は無料です',
  },
  {
    question: 'Visaのタッチ決済に対応していますか？',
    answer:
      'paildのリアルカードはICチップ搭載で、Visaのタッチ決済に対応している店舗でも利用できます。',
  },
  {
    question: '3Dセキュアに対応したECサイト等で使えますか？',
    answer:
      'はい。paildは本人認証（3Dセキュア）が必要な決済にも対応しております。',
  },
  {
    question: '会計ソフトへのAPI連携に対応していますか？',
    answer:
      'はい。現在、freee会計、マネーフォワードクラウド会計とAPI連携しています。その他の会計システムとの連携は入出金履歴のCSVダウンロード機能をご利用ください。',
  },
  {
    question: 'カードはいつ届きますか？',
    answer:
      'リアルカードは発行申請から約5～10営業日程度でお手元に届きます。転送不要の簡易書留郵便で発送しますので、郵便配達員より直接受け取りをお願いします。また、カード発行は管理画面より申請してください。',
  },
];

export const FAQSection: FC = () => {
  const [faqNumber, setFaqNumber] = useState<number | null>(null);

  const handleFaqNumber = (index: number) => {
    faqNumber === index ? setFaqNumber(null) : setFaqNumber(index);
  };

  return (
    <section css={FAQSectionStyle}>
      <div css={FAQSectionHeadingStyle}>
        <p>FAQ</p>
        <h2>よくある質問</h2>
      </div>
      <ul css={FaqListStyle}>
        {FAQList.map((faq, index) => {
          return (
            <li key={index}>
              <div
                css={QuestionWrapperStyle(faqNumber === index + 1)}
                onClick={() => handleFaqNumber(index + 1)}
              >
                <div css={QuestionStyle}>Q</div>
                <p css={ContentStyle}>{faq.question}</p>
                <div></div>
              </div>
              <div css={AnswerWrapperStyle(faqNumber === index + 1)}>
                {faqNumber === index + 1 ? (
                  <>
                    <div css={AnswerStyle}>A</div>
                    <p css={ContentStyle}>{faq.answer}</p>
                  </>
                ) : null}
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

const FAQSectionStyle = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 1120,
  padding: '0 17px',
  position: 'relative',
  gap: 60,

  '@media(max-width: 1119px)': {
    maxWidth: 640,
    gap: 30,
  },

  '@media(max-width: 768px)': {
    maxWidth: 480,
  },
});

const FAQSectionHeadingStyle = css({
  h2: {
    fontSize: 48,
    padding: '24px 0 0',
  },

  p: {
    fontSize: 16,
    color: colors.primary,
    fontWeight: 500,
    letterSpacing: 0.8,
  },

  '@media(max-width: 1119px)': {
    h2: {
      fontSize: 28,
      padding: '20px 0 0',
    },

    p: {
      fontSize: 14,
    },
  },
});
const FaqListStyle = css({
  listStyle: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,

  '@media(max-width: 1119px)': {
    gap: 12,
  },
});

const QuestionWrapperStyle = (isOpen: boolean) =>
  css({
    padding: '26px 30px',
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    borderRadius: 16,
    border: `2px solid ${colors.disabled}`,
    position: 'relative',

    '@media(max-width: 1119px)': {
      padding: '24px 48px 24px 24px',
      gap: 12,
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      width: 18,
      height: 2,
      borderRadius: 1,
      right: 30,
      background: colors.primary,

      '@media(max-width: 1119px)': {
        right: 24,
        gap: 12,
      },
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 2,
      height: isOpen ? 2 : 18,
      borderRadius: 1,
      right: 38,
      background: colors.primary,

      transition: '0.3s',

      '@media(max-width: 1119px)': {
        right: 32,
      },
    },
  });

const QuestionStyle = css({
  minWidth: 48,
  height: 48,
  display: 'grid',
  placeItems: 'center',
  color: colors.white,
  background: colors.primary,
  borderRadius: 24,
});

const AnswerWrapperStyle = (isOpen: boolean) =>
  css({
    opacity: isOpen ? 1 : 0,
    height: isOpen ? 'auto' : 0,
    padding: isOpen ? '26px 30px' : '0 30px',
    marginTop: isOpen ? 20 : 0,
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    background: colors.secondary,
    borderRadius: 16,
    transition: '0.3s',

    '@media(max-width: 1119px)': {
      padding: isOpen ? 24 : '0 24px',
    },
  });

const AnswerStyle = css({
  minWidth: 48,
  height: 48,
  display: 'grid',
  placeItems: 'center',
  color: colors.primary,
  background: colors.white,
  borderRadius: 24,
  border: `2px solid ${colors.primary}`,
});

const ContentStyle = css({
  color: colors.black,
  fontSize: 18,
  fontWeight: 700,
  lineHeight: 2,
  whiteSpace: 'pre-wrap',

  '@media(max-width: 1119px)': {
    fontSize: 14,
  },
});
